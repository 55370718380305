/**
 * 滚动条组件样式。
 * 
 * @author xukai@meituan.com
 * @date 2014-03-21 星期五
 */

.filter-scrollbar {
  display: inline-block;
  position: relative;
}

.filter-scrollbar {
  *display: inline;
}

.filter-scrollbar .inner {
  width: 160px;
  height: 10px;
  background-color: #DDDDDD;
  cursor: pointer;
}

.filter-scrollbar .inner-bg {
  background-color: #FFAD00;
  width: 0%;
  height: 10px;
  _overflow: hidden;
}

.filter-scrollbar .slider {
  width: 16px;
  height: 16px;
  background-color: #FFFFFF;
  position: absolute;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #B5B5B5;
  top: -5px;
  left: -8px;
}

