/**
 *
 * @desc: new footer
 * @date: 2018.08.15
 *
 */
.csr-footer-container {
    width: 100%;
    background-color: #333333;
    position: relative;
}

.middle-line {
    width: 100%;
    height: 1px;
    background-color: #666;
    position: absolute;
    bottom: 70px;
}

.csr-footer {
    margin: 0 auto;
    background-color: #333333;
    height: 358px;
    width: 920px;
    background-size: 100%;
}

.footer-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer-content .top {
    width: 100%;
    height: 287px;
    display: flex;
    align-items: center;
    position: relative;
}

.footer-content .top a {
    text-decoration: none;
    color: inherit;
}

.footer-content .top .left {
    position: absolute;
    font-size: 14px;
    color: #AAA;
    font-family: Microsoft YaHei;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 128px;
    width: 173px;
    top: 71px;
}

.footer-content .top .left ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
}

.footer-content .top .left ul li {
    line-height: normal;
}

.footer-content .top .left li:hover {
    color: #eee;
}

.footer-content .middle {
    position: absolute;
    left: 442px;
    top: 70px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    height: 157px;
    width: 200px;
}

.cooperation .client-service{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.footer-content .middle .title {
    font-size: 22px;
    color: #eee;
    font-family: PingFangSC-Semibold,Microsoft Yahei,Arial,Helvetica,sans-serif;
}

.footer-content .middle .content {
    font-size: 14px;
    font-family: PingFangSC-Regular,Microsoft Yahei,Arial,Helvetica,sans-serif;
    color: #AAA;
}

.footer-content .middle .cooperation {
    margin-bottom: 30px;
}

.footer-content .middle .cooperation .title {
    margin-bottom: 12px;
}

.footer-content .middle .cooperation .content {
    line-height: normal;
}

 .footer-content .middle .client-service .content {
    margin-top: 9px;
    height: 48px;
    line-height: 28px;
}

.footer-content .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 198px;
    height: 130px;
    top: 70px;
    right: 0;
    position: absolute;
    justify-content: space-between;
}

.footer-content .right .right-title {
    font-size: 22px;
    color: #eee;
    font-family: PingFangSC-Semibold,Microsoft Yahei,Arial,Helvetica,sans-serif;
    margin-bottom: 16px;
}

.footer-content .right .QR-code {
    height: 80px;
    display: flex;
    justify-content: flex-start;
}

.footer-content .right .QR-code .QR-code1 {
    background-color: #fff;
    width: 90px;
    height: 90px;
    background: url(../static/img/qrcode-app.png) no-repeat;
    background-size: 100%;
}

.footer-content .right .QR-code .QR-code2{
    margin-left: 16px;
    background-color: #fff;
    background: url(../static/img/qrcode-wxapp.png) no-repeat;
    background-size: 100%;
    width: 90px;
    height: 90px;
}

.footer-content .bottom {
    box-sizing: border-box;
    /* border-top: 1px solid #666; */
    height: 70px;
    width: 100%;
    font-size: 12px;
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content .bottom .img {
    width: 18px;
    height: 18px;
    margin-left: 20px;
    background: url(../static/img/backup.png) no-repeat;
    background-size: 100%;
}

.footer-content .bottom .police {
    margin-left: 6px;
}

.footer-content .bottom .qualification-img{
    width: 18px;
    height: 18px;
    margin-left: 20px;
    /*background: url() no-repeat;*/
    background-size: 100%;
}

.footer-content .bottom a {
    color: #999;
}

.footer-content .bottom .qualification-text{
    color: #888;
    margin-right: 20px;
}
