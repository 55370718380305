/**
 * 页面通用样式库。
 * Ex:
 *   <div class="fr clearfix td"></div>
 *
 * @author XU Kai(xukai@meituan.com)
 * @date 2013.12.26
 *
 */

/* hack IE6 实现position fixed属性 */

/* html {
  _overflow: hidden;
}

body {
  background-color: #f1f1f1;
  height: 100%;
  _overflow: auto;
} */

/* 移除选中的火红色 */

/* ::selection {
  background: #ff6900;
  color:#FFF;
}

::-moz-selection {
  background: #ff6900;
  color: #FFF;
}

::-webkit-selection {
  background: #ff6900;
  color: #FFF;
} */

body {
    background-color: #F5F5F5;
}

.wrapper {
    min-width: 980px;
    _width: expression(((document.compatMode && document.compatMode=='CSS1Compat')? document.documentElement.clientWidth: document.body.clientWidth) < 980? '980px': 'auto');
}

input,
textarea {
    border: 1px solid #C6C6C6;
    padding: 3px;
}

.page-wrap {
    background-color: #F5F5F5;
    margin-bottom: 30px;
    margin-top: 90px;
}

.page-wrap .inner-wrap {
    width: 980px;
    margin: 0 auto;
}

.sprite {
    /*background-image: url(/static/img/sprite/sprite_3.png);*/
    /*_background-image: url(/static/img/sprite/sprite_3.png);*/
}

.qrcode {
    /*background-image: url(/static/img/sprite/qrcode_20151111.png);*/
    /*_background-image: url(/static/img/sprite/qrcode_20151111.png);*/
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.hidden {
    display: none;
}

.unvisialbe {
    visibility: hidden;
}

.clear {
    clear: both;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.clearfix {
    zoom: 1;
}

.font-14 {
    font-size: 14px;
    line-height: 14px;
}

.font-16 {
    font-size: 16px;
    line-height: 16px;
}

.font-18 {
    font-size: 18px;
    line-height: 18px;
}

.bold {
    font-weight: bold;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.transparent-80 {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    opacity: 0.8;
}

.transparent-50 {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
}

.transparent-30 {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=30);
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
    opacity: 0.3;
}

.transparent-60 {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    -khtml-opacity: 0.6;
    opacity: 0.6;
}

.borderradius-1 {
    border-radius: 1px;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    -ms-boder-radius: 1px;
    -o-boder-radius: 1px;
}

.borderradius-2 {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -ms-boder-radius: 2px;
    -o-boder-radius: 2px;
}

.borderradius-3 {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -ms-boder-radius: 3px;
    -o-boder-radius: 3px;
}

.borderradius-5 {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-boder-radius: 5px;
    -o-boder-radius: 5px;
}

.input-shadow {
    -moz-box-shadow: 1px 1px 5px #f6f6f6 inset;
    -webkit-box-shadow: 1px 1px 5px #f6f6f6 inset;
    box-shadow: 1px 1px 5px #f6f6f6 inset;
}

.dialog {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

/* simple button 普通的按钮 */

.s-btn,
.s-btn-white,
.color-btn,
.color-btn-white,
.color-btn-dark,
.sq-btn {
    /*background-image: url(/static/img/btn/s-btn_4.png);*/
    /*_background-image: url(/static/img/btn/_s-btn_4.png);*/
    background-position: 0 0;
    display: inline-block;
    height: 34px;
    line-height: 34px;
    color: #333;
    padding-left: 10px;
    cursor: pointer;
    font-size: 14px;
}

.s-btn-white,
.color-btn-white {
    color: #FFFFFF;
}

.s-btn-white {
    /*background-image: url(/static/img/btn/s-btn-white_2.png);*/
    /*_background-image: url(/static/img/btn/_s-btn-white_2.png);*/
    color: #444444;
}

.field .s-btn-white {
    /*background-image: url(/static/img/btn/s-btn_4.png);*/
    /*_background-image: url(/static/img/btn/_s-btn_4.png);*/
}

.s-btn .s-btn,
.s-btn-white .s-btn-white,
.color-btn .color-btn,
.color-btn-white .color-btn-white,
.color-btn-dark .color-btn-dark,
.sq-btn .sq-btn {
    background-position: 100% 0;
    padding-right: 20px;
    padding-left: 10px;
}

/* 解决高级IE浏览器下，按钮会产生错开的问题 */

.s-btn span.s-btn,
.s-btn-white span.s-btn-white,
.color-btn span.color-btn,
.color-btn-white span.color-btn-white,
.color-btn-dark span.color-btn-dark,
.sq-btn span.sq-btn {
    display: block;
    _display: inline-block;
}

.s-btn:hover,
.s-btn-white:hover {
    background-position: 0 -34px;
}

.s-btn:hover .s-btn,
.s-btn-white:hover .s-btn-white {
    background-position: 100% -34px;
}

/* 当小按钮处于disabled的状态情况下 */

.s-btn-disabled,
.s-btn-disabled .s-btn,
.s-btn-disabled .s-btn-white {
    /*background-image: url(/static/img/btn/s-btn-disabled.png);*/
}

.s-btn-disabled:hover {
    background-position: 0 0;
}

.s-btn-disabled:hover .s-btn,
.s-btn-disabled:hover .s-btn-white {
    background-position: 100% 0;
}

.color-btn {
    height: 25px;
    line-height: 25px;
    padding-left: 5px;
    /*background-image: url(/static/img/btn/color-btn.png);*/
    /*_background-image: url(/static/img/btn/_color-btn.png);*/
}

.color-btn-white {
    height: 25px;
    line-height: 25px;
    padding-left: 5px;
    /*background-image: url(/static/img/btn/color-btn-white.png);*/
    /*_background-image: url(/static/img/btn/_color-btn-white.png);*/
}

.color-btn-dark {
    height: 25px;
    line-height: 25px;
    padding-left: 5px;
    /*background-image: url(/static/img/btn/color-btn-dark.png);*/
    /*_background-image: url(/static/img/btn/_color-btn-dark.png);*/
}

.sq-btn {
    height: 27px;
    line-height: 27px;
    /*background-image: url(/static/img/btn/sq-btn.png);*/
    /*_background-image: url(/static/img/btn/_sq-btn.png);*/
}

.color-btn .color-btn,
.color-btn-white .color-btn-white,
.color-btn-dark .color-btn-dark {
    padding-right: 10px;
    padding-left: 5px;
}

.scroll-loading {
    /*background-image: url(/static/img/dyl_1.png);*/
    background-position: center;
    background-repeat: no-repeat;
}

/* 下价商家公示 */

.announcement-banner {
    background: #ffffff;
    width: 980px;
    height: 102px;
    padding: 22px;
    box-sizing: border-box;
    color: #d06603;
    font-size: 14px;
    margin-bottom: 10px;
}

.banner-word {
    font-weight: bold;
    margin-bottom: 6px;
}

.announcement-icon {
    display: inline-block;
    /*background: url("../../img/announcement-icon.png") no-repeat;*/
    width: 16px;
    height: 12px;
}

.link-annocement {
    text-decoration: underline;
}

/* 下价商家公示 */
